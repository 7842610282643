<template>
  <v-container>

    <!-- Module title -->
    <v-row>
      <v-col>
        <h2 class="mt-3 mb-3">Proyectos</h2>
        <p class="mb-0">Estos son los proyectos impulsados por el Área Artístico Cultural del Departamento Estudiantil UCN</p>
      </v-col>
    </v-row>

    <v-row class="mt-3 mb-6">
      <v-col cols="12">
        <v-card class="titleUnderline" min-height="12px" elevation="1" />
      </v-col>
    </v-row>

    <!-- Projects Components -->
    <v-row>
      <v-col>
        <CADEG />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <CultureInAction />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Labqui />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <LiterarySpace />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <EthnicDanceContest />
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
import CADEG from '@/components/projects/CADEG'
import CultureInAction from '@/components/projects/cultureinaction'
import LiterarySpace from '@/components/projects/literaryspace'
import Labqui from '@/components/projects/labqui'
import EthnicDanceContest from '@/components/projects/ethnicdancecontest'

export default {
  name: "Projects",
  components: {
    CADEG,
    CultureInAction,
    LiterarySpace,
    Labqui,
    EthnicDanceContest
  },
}
</script>

<style lang="scss" scoped>

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}

</style>
