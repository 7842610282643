<template>
  <v-card
    color="#385F73"
    dark
    class="mb-4"
  >
    <v-row class="px-3">
      <v-col lg="6" md="12">
        <v-card-title class="text-h5" style="word-break: break-word;">Certamen Interuniversitario de Danza Étnica y Moderna</v-card-title>

        <v-card-subtitle>2017</v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text>

          <p class="text-h6">Adjudicación y financiamiento</p>

          <p>Financiado por el Fondo de Desarrollo Institucional (FDI), línea de emprendimiento estudiantil del Ministerio de Educación y fue desarrollado por Yasna Aguirre Pasten, estudiante de Ingeniería Civil Industrial de la Universidad Católica del Norte, Sede Coquimbo y profesora del Taller de Cueca Brava del Galpón Cultural UCN. El proyecto contó con el auspicio y organización del Área Artístico Cultural del Departamento Estudiantil UCN, Sede Coquimbo.</p>

          <p class="text-h6">Fundamentación</p>

          <p>
            El Primer Certamen Interuniversitario de Danza Étnica y Moderna, nace por la necesidad de abrir nuevos espacios para la expresión artística, rescatando a través de la danza, las expresiones de los pueblos originarios de Chile con la finalidad de acercar a la comunidad a nuestras raíces. Por otro lado, se fomentó la aceptación de minorías sexuales, a través de bailes modernos, como la popular danza de “New Cabaret en tacones” o “Heels”, cuyo objetivo fue que bailarines (mayores de 18 años), tanto universitarios como externos, pudieran expresarse a través de las temáticas que comprendió el certamen (Étnica, de pueblos originarios de Chile, y libre en la modalidad New Cabaret en Tacones).
            El Certamen abarco distintos estilos de danzas y además se realizaron cuatro workshops abiertos a todo público, cuya finalidad, además de enseñar distintas disciplinas de danzas, fue crear un ambiente grato y de encuentro para los participantes. También, en las jornadas de workshops, se dictó una ponencia de pueblos originarios de Chile.
          </p>

          <p class="text-h6">Fechas</p>

          <p><strong>Inicio de talleres:</strong> Noviembre 2019</p>
          <p>
            <strong>Ponencias y workshops:</strong> Miércoles 25 de Octubre de 2017.
            <strong>Certamen:</strong> Jueves 26 de Octubre de 2017 en Centro Cultural Palace, Coquimbo.
          </p>

          <p class="text-h6">Equipo de Trabajo</p>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>Yasna Aguirre Pastén</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Organizadora Certamen Interuniversitario de Danza Étnica y Moderna</v-list-item-subtitle>
              <v-list-item-subtitle>Profesora Taller de Cueca Brava Galpón Cultural UCN</v-list-item-subtitle>
              <v-list-item-subtitle>Estudiante de Ingeniería Civil Industrial UCN</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>Linda Cancino Flores</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Jefa Área Artístico Cultural del Departamento Estudiantil UCN</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <p class="text-h6">Redes Sociales</p>

          <v-btn href="https://www.facebook.com/Certamen-Interuniversitario-de-Danza-%C3%89tnica-y-Moderna-UCN-222378011616739/" small class="mb-2">Certamen Interuniversitario de Danza Étnica y Moderna UCN</v-btn>

          <p class="text-h6">Noticias</p>

          <v-btn href="http://www.elcoquimbano.cl/2017/08/22/invitan-a-participar-de-certamen-de-danza-etnica-y-moderna-regional/" small class="mb-2">
            Invitan a participar de Certamen de Danza Étnica y Moderna Regional
          </v-btn>

          <v-btn href="http://www.noticias.ucn.cl/destacado/invitan-a-certamen-de-danza-etnica-y-moderna/" small class="mb-2">
            Invitan a Certamen de Danza Étnica y Moderna
          </v-btn>

        </v-card-text>

      </v-col>

      <v-col lg="6" md="12">
        <v-carousel cycle>
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/1.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/3.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/4.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/5.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/7.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/8.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/9.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/10.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/11.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/12.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/13.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/14.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/15.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/16.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/17.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/18.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/19.jpg" />
          <v-carousel-item src="@/assets/projects/ethnicdancecontest/20.jpg" />
        </v-carousel>
      </v-col>
      
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'EthnicDanceContest',
}
</script>
