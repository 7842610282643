<template>
  <v-card
    color="#5c9c41"
    dark
    class="mb-4"
  >
    <v-row class="px-3">
      <v-col lg="6">

        <div>
          <v-card-title class="text-h5">CADEG</v-card-title>

          <v-card-subtitle>2021-2022</v-card-subtitle>

          <v-divider></v-divider>

          <v-card-text>

            <p class="text-h6">Adjudicación y financiamiento</p>

            <p>Proyecto financiado con Fondos de Desarrollo Institucional (FDI – UCN 20104), línea Emprendimiento Estudiantil del Ministerio de Educación.</p>

            <p class="text-h6">Fundamentación</p>

            <p>
              Cultura y Arte desde la Diversidad y Equidad de Género (CADEG) es un proyecto inclusivo, organizado por las estudiantes Fiama Muñoz y María José Caballero, estudiantes de la carrera de Derecho de la Universidad Católica del Norte, y Linda Cancino Flores, Jefa del Área Artístico Cultural UCN, que nace el año 2020 con la intención de educar y visibilizar en el espacio universitario y en toda la región de Coquimbo, manifestaciones sociales, culturales y artísticas de disidencias sexuales y de género, a través de conversatorios tales como "Diversidad y equidad de género desde las ciencias sociales", "Activismo LGBTIQA+" y "Arte y cultura disidente" y muestras artísticas. Queremos que esta plataforma sea un vehículo para todes les artistas y activistas que día a día luchan por los derechos de la comunidad LGTBIQA+.
            </p>

          </v-card-text>

        </div>

      </v-col>

      <v-col lg="6">

        <v-carousel cycle>
          <v-carousel-item
            src="@/assets/projects/cadeg/1.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cadeg/2.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cadeg/3.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cadeg/4.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cadeg/5.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cadeg/6.png"
          />
          <v-carousel-item
            src="@/assets/projects/cadeg/7.png"
          />
        </v-carousel>

      </v-col>

    </v-row>

    <v-card-text>
      <p class="text-h6">Equipo de Trabajo</p>

      <v-row>
        <v-col md="12" sm="12">

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>María José Caballero Torres</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Coordinadora general - Estudiante de Derecho UCN</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Fiama Muñoz Araya</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Coordinadora general - Estudiante de Derecho UCN</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Linda Cancino Flores</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Jefa Área Artístico Cultural UCN</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-col>
      </v-row>

    </v-card-text>

    <v-row>
      <v-card-text>

        <v-col cols="12">
          <p class="text-h6">Fechas</p>

          <ul class="mb-3">
            <li><strong>I Conversatorio “Diversidad y equidad de género desde las ciencias jurídicas y sociales</strong>:
              5 de julio de 2022 - Online
              <p><a style="color:white" href="https://www.facebook.com/100071350400320/videos/166072092250613">Link de Transmision</a></p>
            </li>

            <li><strong>II Conversatorio "Activismo LGBTQIA+"</strong>:
              7 de enero de 2022 - Online
              <p><a style="color:white" href="https://www.facebook.com/100071350400320/videos/660902164918783">Link de transmisión</a></p>
            </li>

            <li><strong>III Conversatorio "Arte y cultura disidente"</strong>:
              27 de mayo de 2022 - Galpón Cultural UCN
              <p><a style="color:white" href="https://www.facebook.com/100071350400320/videos/713646499850106">Link de Transmision</a></p>
            </li>

            <li><strong>Muestra Artística de Arte Disidente</strong>:
              27 de mayo de 2022 - Galpón Cultural UCN
            </li>
          </ul>

          <p class="text-h6">Redes Sociales</p>

          <p><a style="color:white" href="https://www.facebook.com/profile.php?id=100071350400320">Fan Page Facebook</a></p>
          <p><a style="color:white" href="https://www.instagram.com/cadeg_chile/">Instagram</a></p>

          <p class="text-h6">Noticias</p>

          <v-btn href="https://www.noticias.ucn.cl/destacado/ucn-invita-al-primer-encuentro-artistico-nacional-para-la-diversidad-y-equidad-de-genero/" small class="mb-2">
            "UCN invita al primer encuentro artístico nacional para la diversidad y equidad de género"
          </v-btn>

          <v-btn href="https://www.noticias.ucn.cl/destacado/estudiantes-ucn-impulsan-proyecto-para-visibilizar-la-cultura-y-arte-desde-la-diversidad-y-equidad-de-genero/" small class="mb-2">
            "Estudiantes UCN impulsan proyecto para visibilizar la cultura y arte desde la Diversidad y Equidad de Género"
          </v-btn>
        </v-col>

      </v-card-text>
    </v-row>

  </v-card>
</template>

<script>
export default {
  name: 'CADEG',
}
</script>
