<template>
  <v-card
    color="#e84d22"
    dark
    class="mb-4"
  >
    <v-row class="px-3">
      <v-col lg="6">
        <v-card-title class="text-h5" style="word-break: break-word;">Laboratorio de Innovación y Emprendimiento Cultural Inclusivo "LABQUI"</v-card-title>

        <v-card-subtitle>2019</v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text>

          <p class="text-h6">Adjudicación y financiamiento</p>

          <p>Proyecto financiado por el Fondo Nacional de Desarrollo Cultural y las Artes, FONDART Regional 2019 línea Actividades Formativas, del Ministerio de las Culturas, las Artes y el Patrimonio, adjudicado por el Área Artístico Cultural, con la colaboración del Área de Bienestar Estudiantil, ambas áreas del Departamento Estudiantil UCN, sede Coquimbo y el Instituto de Políticas Públicas UCN.</p>


          <p class="text-h6">Fundamentación</p>

          <p>
            El Laboratorio de Innovación y Emprendimiento Cultural Inclusivo, LABQUI, fue una iniciativa que nació y se desarrolló desde el Área Artístico Cultural, con el apoyo de Bienestar Estudiantil, ambas áreas del Departamento Estudiantil UCN, y el apoyo del Instituto de Políticas Públicas UCN.
          </p>

          <p>
            LABQUI se origina para satisfacer las necesidades de profesionalización y orientación para impulsar y desarrollar proyectos y emprendimientos de Industria Creativa incorporando herramientas inclusivas y así, estimular el desarrollo emergente de industrias creativas de la Región de Coquimbo, enfocado a gestores, artistas y emprendedores culturales de la Industria Creativa de la Región de Coquimbo.
          </p>

          <p>
            LABQUI estuvo integrado por 12 módulos que concentraron materias en Industrias Creativas, Herramientas para la Inclusión Social, Finanzas y Costos para la Gestión, entre otros. La capacitación también contó con instancias de mentoría, donde los participantes tuvieron asesorías especializadas en distintas áreas del emprendimiento cultural por estudiantes UCN. El proyecto finalizó con workshops, dos instancias de giras en espacios culturales de la Región y una ceremonia de cierre.
          </p>

        </v-card-text>

      </v-col>

      <v-col lg="6">

        <v-carousel cycle>
          <v-carousel-item src="@/assets/projects/labqui/1.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/2.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/3.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/4.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/5.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/6.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/7.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/8.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/9.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/10.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/11.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/12.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/13.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/14.jpg" />
          <v-carousel-item src="@/assets/projects/labqui/15.jpeg" />
        </v-carousel>

      </v-col>
    </v-row>

    <v-card-text>

      <p class="text-h6">Equipo de Trabajo</p>

      <v-row>
  
        <v-col md="12" sm="12">

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Linda Cancino Flores</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Directora Proyecto LABQUI - Jefa Área Artístico Cultural del Departamento Estudiantil</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Tatiana López Varela</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Asesora en Inclusión Social LABQUI - Jefa Área Bienestar del Departamento Estudiantil</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Daniela Henríquez Encamilla</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Asesora Social LABQUI</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Mario Castro Vargas</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Coordinador LABQUI</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Cintia Díaz Ortiz</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Coordinador LABQUI</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-col>

      </v-row>
    </v-card-text>

    <v-row>
      <v-card-text>

        <v-col cols="12">
          <p class="text-h6">Fechas</p>

          <ul class="mb-3">
            <li><strong>Workshop y Taller de Workshop I: “Industrias Creativas: Considerar para innovar”</strong>:
              Sábado 06 de julio, Casa de las Artes, ubicada en calle Aldunate N° 699, Coquimbo.
            </li>

            <li><strong>Workshop II: “Innovación en la Cultura y Propiedad Intelectual”</strong>:
              Sábado 27 de julio, Auditorio de la Biblioteca Regional Gabriela Mistral
            </li>

            <li><strong>Workshop III: “Tecnología en la Cultura: Pensamiento Creativo y Proceso de Diseño para la Construcción de una Identidad Visual”</strong>:
              Sábado 20 de julio, Teatro Municipal de Ovalle.
            </li>

            <li><strong>Workshop IV: “Inclusión en la Cultura”</strong>:
              Sábado 27 de julio
            </li>

            <li><strong>Gira I LABQUI</strong>:
              Viernes  02 de agosto, en la terraza de Mall Vivo Coquimbo.
            </li>

            <li><strong>Gira II LABQUI</strong>:
              Viernes 09 de agosto, en la Biblioteca Regional Gabriela Mistral.
            </li>

            <li><strong>Ceremonia de Certificación LABQUI</strong>:
              Miércoles 21 de agosto, Salón Arcángel UCN
            </li>
          </ul>

          <p class="text-h6">Redes Sociales</p>

          <p>Fan Page de Facebook: Labqui, Laboratorio de Innovación y Emprendimiento Cultural Inclusivo</p>

          <p>Instagram: @labquiucn</p>

          <v-btn href="https://www.facebook.com/watch/live/?ref=watch_permalink&v=408975149734566" small class="mb-2">I Gira LABQUI</v-btn>

          <p class="text-h6">Noticias</p>

          <v-btn href="https://www.noticias.ucn.cl/destacado/proyecto-ucn-potenciara-la-industria-creativa-inclusiva-en-la-region-de-coquimbo/" small class="mb-2">Proyecto UCN potenciará la industria creativa inclusiva en la Región de Coquimbo</v-btn>

          <v-btn href="https://www.noticias.ucn.cl/destacado/labqui-ucn-inicia-su-trabajo-con-artistas-y-gestores-culturales-de-coquimbo/" small class="mb-2">LABQUI UCN inicia su trabajo con artistas y gestores culturales de Coquimbo</v-btn>
        </v-col>

      </v-card-text>
    </v-row>

  </v-card>
</template>

<script>
export default {
  name: 'Labqui',
}
</script>
