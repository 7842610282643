<template>
  <v-card
    color="#1F7087"
    dark
    class="mb-4"
  >
    <v-row class="px-3">
      <v-col lg="6">

        <div>
          <v-card-title class="text-h5">Cultura en acción para todas y todos</v-card-title>

          <v-card-subtitle>2019 - 2020</v-card-subtitle>

          <v-divider></v-divider>

          <v-card-text>

            <p class="text-h6">Adjudicación y financiamiento</p>

            <p>Proyecto Fondo Nacional de Proyectos Inclusivos (FONAPI) 2019 del Servicio Nacional de la Discapacidad (SENADIS) adjudicado por el Área de Bienestar Estudiantil con la colaboración del Área Artístico Cultural, ambas áreas del Departamento Estudiantil de la Universidad Católica del Norte.</p>

            <p class="text-h6">Fundamentación</p>

            <p>El proyecto contempló el desarrollo de talleres artísticos culturales inclusivos a través del Área Artístico Cultural con la coordinación de Bienestar Estudiantil de la Universidad Católica del Norte, de la sede Coquimbo. En él participaron inicialmente personas con discapacidad intelectual, usuarias de la Oficina de Gestión Inclusiva de la Municipalidad de Coquimbo, Hogar por Siempre y Escuela Nueva Esperanza y estudiantes UCN.

            Durante su ejecución, surgieron  diversos desafíos  que implicaron adaptarnos a nuevos escenarios, ampliando su cobertura  a participantes de nuestra comunidad universitaria (funcionarios, académicos y profesionales de apoyo a la academia) y también de la comuna. El proyecto finalizó con una muestra artística virtual.
            </p>

            <p class="text-h6">Equipo de Trabajo</p>

            
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>Tatiana López Varela</v-list-item-title>
                <v-divider class="mb-1"></v-divider>
                <v-list-item-subtitle>Coordinadora General Cultura en accíon para todas y todos.</v-list-item-subtitle>
                <v-list-item-subtitle>Jefa Área Bienestar Estudiantil</v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>

            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>Linda Cancino Flores</v-list-item-title>
                <v-divider class="mb-1"></v-divider>
                <v-list-item-subtitle>Coordinadora Talleres Culturales.</v-list-item-subtitle>
                <v-list-item-subtitle>Jefa Área Artístico Cultural del Departamento Estudiantil</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <p class="text-h6">Fechas</p>

            <p><strong>Inicio de talleres:</strong> Noviembre 2019</p>
            <p>
              <strong>Muestra artística virtual:</strong> 5 de agosto 2020 - 18 hrs.
              <v-btn href="https://www.facebook.com/113953666934520/videos/324181795386925" small class="ml-2">Link del evento</v-btn>
            </p>

            <p class="text-h6">Redes Sociales</p>

            <v-btn href="" small class="mb-2">Fan Page de Facebook: Cultura en acción para todas y todos</v-btn>

            <p class="text-h6">Noticias</p>

            <v-btn href="https://www.noticias.ucn.cl/noticias/extension-cultural/personas-en-situacion-de-discapacidad-aprendieron-a-bailar-con-clases-virtuales-dirigidas-por-la-ucn/?fbclid=IwAR2CrItVCpNzwZynzgRqQk88GlpB8nl7FmRRLmM4GFmLrzQBiQQpIJeDfek" small class="mb-2">Personas en situación de discapacidad aprendieron a bailar con clases virtuales dirigidas por la UCN</v-btn>

          </v-card-text>

        </div>

      </v-col>

      <v-col lg="6">

        <v-carousel cycle>
          <v-carousel-item
            src="@/assets/projects/cultureinaction/1.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cultureinaction/2.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cultureinaction/3.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cultureinaction/4.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cultureinaction/5.jpg"
          />
          <v-carousel-item
            src="@/assets/projects/cultureinaction/6.jpg"
          />
        </v-carousel>

      </v-col>

    </v-row>

  </v-card>
</template>

<script>
export default {
  name: 'CultureInAction',
}
</script>