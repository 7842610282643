<template>
  <v-card
    color="#952175"
    dark
    class="mb-4"
  >
    <v-row class="px-3">

      <v-col lg="6" md="12">
        <v-card-title class="text-h5">Espacio Literario</v-card-title>

        <v-card-subtitle>2018</v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text>

          <p class="text-h6">Adjudicación y financiamiento</p>

          <p>Proyecto Estudiantil Universidad Católica del Norte Sede Coquimbo 2018</p>


          <p class="text-h6">Fundamentación</p>

          <p>Este proyecto es una extensión del proyecto estudiantil adjudicado por estudiantes de la universidad llamado “Trueke literario” en el año 2013 y consistió en extender este Trueke literario a un espacio de literatura activo a través de un librero, en donde los estudiantes tengan a su libre disposición libros de literatura universal.

          </p>

          <p>
            Por otro lado, el Galpón Cultural propicionó un espacio acogedor ambientado con sillones, alfombra y lámparas para entregar un lugar para la lectura. Además el Galpón también apoyo con un pendón explicativo sobre el funcionamiento de este “Trueke literario”.
          </p>

          <p class="text-h6">Estudiantes</p>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Camila Guzmán Cortés</v-list-item-title>
              <v-divider class="mb-1"></v-divider>
              <v-list-item-subtitle>Estudiante de Nutrición y dietética</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <p class="text-h6">Fechas</p>

          <p>Realización durante el año 2018</p>

        </v-card-text>

      </v-col>

      <v-col lg="6" md="12">
        <v-carousel>
          <v-carousel-item src="@/assets/projects/literaryspace/1.jpg" />
        </v-carousel>
      </v-col>

    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'LiterarySpace',
}
</script>
